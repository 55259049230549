import React, { useEffect } from "react";
import NavBar from "./Navbar";
import { Route, Routes, useNavigate } from "react-router";
import { Layout } from "antd";
import NotFound from "./notFound/NotFound";
import Bundle from "./Bundle/Bundle";
import Transactions from "./Transaction/Transaction";
import Cards from "./Card/Cards";
import Ticket from "./Ticket/Ticket";
import Kiosks from "./Kiosks/Kiosks";
import Hardwares from "./Hardware/Hardwares";
import ValidatorScans from "./Logs/ValidatorScans";
import HardwareLogs from "./Logs/HardwareLogs";
import MaintenanceLogs from "./Logs/MaintenanceLogs";
import { useSelector } from "react-redux";
import ValidatorsList from "./Validator/Validators";
import Users from "./Users/User";
import KioskAndValidatorUsers from "./Users/KioskAndValidatorUsers";
import CardTypes from "./CardTypes/CardTypes";
import Dashboard from "./Dashboard/Dashboard";
import SpecialCards from "./SpecialCards/SpecialCards";

const LoggedInArea = () => {
  const { Content } = Layout;
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);
  // ? This is the main component that will be rendered when the user is logged in if the user is not logged in the user will be redirected to the login page
  useEffect(() => {
    if (!auth.token) {
      navigate("/login");
    }
  }, [auth.token]);

  return (
    <>
      <NavBar />
      <Content id="content" style={{ margin: 30, height: "100%" }}>
        <Routes>
          <Route index element={<Dashboard />} />
          <Route path="/kiosks" element={<Kiosks />} />
          <Route path="/hardwares" element={<Hardwares />} />
          <Route path="/bundles" element={<Bundle />} />
          <Route path="/cards" element={<Cards />} />
          <Route path="/card-types" element={<CardTypes />} />
          <Route path="/special-cards" element={<SpecialCards />} />
          <Route path="/tickets" element={<Ticket />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/users/admins" element={<Users type={"Admin"} />} />
          <Route
            path="/users/super-admins"
            element={<Users type={"SuperAdmin"} />}
          />
          <Route
            path="/users/technicians"
            element={<Users type={"Technician"} />}
          />
          <Route
            path="/users/drivers"
            element={<Users type={"Driver"} />}
          />
          <Route
            path="/users/kiosk-users"
            element={<KioskAndValidatorUsers type={"KioskUser"} />}
          />
          <Route
            path="/users/validator-users"
            element={<KioskAndValidatorUsers type={"ValidatorUser"} />}
          />
          <Route path="/logs/validator-logs" element={<ValidatorScans />} />
          <Route path="/logs/hardware-logs" element={<HardwareLogs />} />
          <Route path="/logs/maintenance-logs" element={<MaintenanceLogs />} />
          <Route path="/validators" element={<ValidatorsList />} />
          {/* <Route path="/app/main/location" element={<Locations />} /> */}
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Content>
    </>
  );
};

export default LoggedInArea;
