import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from '../axios'

const initialState = {
    transactionByMonth: null,
    totalTicketsCardsRevenueData: null,
    totalKiosks: null,
    scanLocations: []
};

export const dashboardData = createAsyncThunk('Dashboard/dashboardData', async (includePreviousYear) => {
    try {
        const response = await axios.get(`/dashboard?includePreviousYear=${includePreviousYear}`);
        return response.data;
    } catch (error) {
        const customError = {
            name: error.code,
            message: error.message,
        };
        throw customError;
    }
});

export const getTotalTicketsCardsRevenue = createAsyncThunk('Dashboard/getTotalTicketsCardsRevenue', async (dateRange) => {
    try {
        const response = await axios.patch(`/totalTicketsCardsRevenue`, dateRange);
        return response.data;
    } catch (error) {
        const customError = {
            name: error.code,
            message: error.message,
        };
        throw customError;
    }
});

export const getTotalKiosks = createAsyncThunk('Dashboard/getTotalKiosks', async () => {
    try {
        const response = await axios.get(`/totalKiosks`);
        return response.data;
    } catch (error) {
        const customError = {
            name: error.code,
            message: error.message,
        };
        throw customError;
    }
});

export const getScanLocations = createAsyncThunk('Dashboard/getScanLocations', async (period) => {
    try {
        const response = await axios.get(`/scan-locations?period=${period}`);
        return response.data;
    } catch (error) {
        const customError = {
            name: error.code,
            message: error.message,
        };
        throw customError;
    }
});



export const dashboardSlice = createSlice({
    name: 'Dashboard',
    initialState,
    reducers:{},
    extraReducers:{
        [dashboardData.fulfilled]: (state, action) => {
            state.transactionByMonth = action.payload;
        },
        [getTotalTicketsCardsRevenue.fulfilled]: (state, action) => {
            state.totalTicketsCardsRevenueData = action.payload;
        },
        [getTotalKiosks.fulfilled]: (state, action) => {
            state.totalKiosks = action.payload;
        },
        [getScanLocations.fulfilled]: (state, action) => {
            state.scanLocations = action.payload;
        },
    }
});

export default dashboardSlice.reducer;