import React, { useEffect, useState } from "react";
import {
  Typography,
  Layout,
  Menu,
  Dropdown,
  Spin,
  Button,
  message,
  Affix,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  LogoutOutlined,
  GoldOutlined,
  WalletOutlined,
  CreditCardOutlined,
  PartitionOutlined,
  TeamOutlined,
  AppstoreOutlined,
  FileTextOutlined,
  MenuOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/authSlice";
import CustomAvatar from "../global/Avatar/CustomAvatar";
import { changePreferredLanguage, getUserProfile } from "../redux/userSlice";
import TicketSVG from "../icons/ticket.svg";
import TicketBossLogo from "../icons/ticketbosslogo.png";
import enFlag from "../icons/flags/uk.png"; // Path to English flag
import itFlag from "../icons/flags/italy.png"; // Path to Italian flag
import { useTranslation } from "react-i18next";

const { Header } = Layout;
const { Title } = Typography;

const horizontalBarStyle = {
  display: "flex",
  alignItems: "center",
  borderBottom: "1px solid #f0f0f0",
  backgroundColor: "white",
};

const NavBar = () => {
  // state to detect the sceen size
  const [isMobile, setIsMobile] = useState(false);
  // state to detect the sceen size
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeMenu, setActiveMenu] = React.useState([]);

  const { profile } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const style = {
    navItem: {
      marginLeft: "0.5em",
      fontSize: 16,
    },
  };
  useEffect(() => {
    switch (window.location.pathname) {
      case "/app/main":
        setActiveMenu(["Dashboard"]);
        break;
      case "/app/main/kiosks":
        setActiveMenu(["kiosks"]);
        break;
      case "/app/main/hardwares":
        setActiveMenu(["hardwares"]);
        break;
      case "/app/main/bundles":
        setActiveMenu(["bundles"]);
        break;
      case "/app/main/cards":
        setActiveMenu(["cards"]);
        break;
      case "/app/main/card-types":
      setActiveMenu(["cardTypes"]);
      break;
      case "/app/main/special-cards":
        setActiveMenu(["specialCards"]);
        break;
      case "/app/main/locations":
        setActiveMenu(["locations"]);
        break;
      case "/app/main/tickets":
        setActiveMenu(["tickets"]);
        break;
      case "/app/main/transactions":
        setActiveMenu(["transactions"]);
        break;
      case "/app/main/logs/validatorscans":
        setActiveMenu(["validatorlogs"]);
        break;
      case "/app/main/logs/hardwarelogs":
        setActiveMenu(["hardwarelogs"]);
        break;
      case "/app/main/logs/maintenancelogs":
        setActiveMenu(["maintenancelogs"]);
        break;
      case "/app/main/users":
        setActiveMenu(["users"]);
        break;
      case "/app/main/validators":
        setActiveMenu(["validators"]);
        break;
      case "/app/main/users/super-admins":
        setActiveMenu(["users"]);
        break;
      case "/app/main/users/admins":
        setActiveMenu(["users"]);
        break;
      case "/app/main/users/technicians":
        setActiveMenu(["users"]);
        break;
      case "/app/main/users/drivers":
        setActiveMenu(["users"]);
        break;
      case "/app/main/users/kiosk-users":
        setActiveMenu(["users"]);
        break;
      case "/app/main/users/validator-users":
        setActiveMenu(["users"]);
        break;
      default:
        setActiveMenu([]);
        break;
    }
  }, [window.location.pathname]);

  const handleLogout = () => {
    dispatch(logout())
      .unwrap()
      .then(() => navigate(`/app/login`));
  };
  const userProfileMenu = (
    <Menu>
      {/*  <Menu.Item key="profile">
        <Link to="/app/main/profile">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <UserOutlined />
            <span style={style.navItem}>Profile</span>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Divider /> */}
      <Menu.Item onClick={handleLogout} key="logout">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <LogoutOutlined />
          <span style={style.navItem}>{t("connectedUserDropdown.logout")}</span>
        </div>
      </Menu.Item>
    </Menu>
  );
  const items = [
    {
      label: (
        <Link to="/app/main">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DashboardOutlined />
            {!isMobile && (
              <span style={style.navItem}>{t("navbar.dashboard")}</span>
            )}
          </div>
        </Link>
      ),
      key: "Dashboard",
      role: ["ADMIN_ROLE", "SUPER_ADMIN_ROLE"],
    },
    {
      label: (
        <Link to="/app/main/kiosks">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <GoldOutlined />
            {!isMobile && (
              <span style={style.navItem}>{t("navbar.kiosks")}</span>
            )}
          </div>
        </Link>
      ),
      key: "kiosks",
      role: ["ADMIN_ROLE", "SUPER_ADMIN_ROLE"],
    },
    {
      label: (
        <Link to="/app/main/hardwares">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PartitionOutlined />
            {!isMobile && (
              <span style={style.navItem}>{t("navbar.hardwares")}</span>
            )}
          </div>
        </Link>
      ),
      key: "hardwares",
      role: ["SUPER_ADMIN_ROLE"],
    },
    {
      label: (
        <Link to="/app/main/validators">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CreditCardOutlined />
            <span style={style.navItem}>{t("navbar.validators")}</span>
          </div>
        </Link>
      ),
      key: "validators",
      role: ["ADMIN_ROLE", "SUPER_ADMIN_ROLE"],
    },
    {
      label: (
        <Link to="/app/main/bundles">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AppstoreOutlined />
            {!isMobile && (
              <span style={style.navItem}>{t("navbar.bundles")}</span>
            )}
          </div>
        </Link>
      ),
      key: "bundles",
      role: ["ADMIN_ROLE", "SUPER_ADMIN_ROLE"],
    },
    {
      label: (
        <Link to="/app/main/transactions">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <WalletOutlined />
            <span style={style.navItem}>{t("navbar.transactions")}</span>
          </div>
        </Link>
      ),
      key: "transactions",
      role: ["ADMIN_ROLE", "SUPER_ADMIN_ROLE"],
    },
    {
      label: (
        <Link to="/app/main/tickets">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={TicketSVG} style={{ width: "16px", height: "16px" }} />
            {!isMobile && (
              <span style={style.navItem}>{t("navbar.tickets")}</span>
            )}
          </div>
        </Link>
      ),
      key: "tickets",

      role: ["ADMIN_ROLE", "SUPER_ADMIN_ROLE"],
    },
    {
      label: (
        <Link to="/app/main/cards">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CreditCardOutlined />
            {!isMobile && (
              <span style={style.navItem}>{t("navbar.cards")}</span>
            )}
          </div>
        </Link>
      ),
      key: "cards",
      role: ["ADMIN_ROLE", "SUPER_ADMIN_ROLE"],
      children: [
        {
          key: "cards",
          label: (
            <Link to="/app/main/cards">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <CreditCardOutlined />
                <span style={style.navItem}>{t("navbar.standardCards")}</span>
              </div>
            </Link>
          ),
          role: ["SUPER_ADMIN_ROLE"],
        },
        {
          key: "specialCards",
          label: (
            <Link to="/app/main/special-cards">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <CreditCardOutlined />
                <span style={style.navItem}>{t("navbar.specialCards")}</span>
              </div>
            </Link>
          ),
          role: ["SUPER_ADMIN_ROLE"],
        },
        {
          key: "cardTypes",
          label: (
            <Link to="/app/main/card-types">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <MenuOutlined />
                <span style={style.navItem}>{t("navbar.cardTypes")}</span>
              </div>
            </Link>
          ),
          role: ["SUPER_ADMIN_ROLE", "ADMIN_ROLE"],
        },
      ],
    },
    {
      label: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <FileTextOutlined />
          {!isMobile && <span style={style.navItem}>{t("navbar.logs")}</span>}
        </div>
      ),
      key: "logs",
      role: ["ADMIN_ROLE", "SUPER_ADMIN_ROLE"],
      children: [
        {
          key: "validatorlogs",
          label: (
            <Link to="/app/main/logs/validator-logs">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <FileTextOutlined />
                <span style={style.navItem}>{t("navbar.validatorLogs")}</span>
              </div>
            </Link>
          ),
          role: ["SUPER_ADMIN_ROLE"],
        },
        {
          key: "hardwarelogs",
          label: (
            <Link to="/app/main/logs/hardware-logs">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <FileTextOutlined />
                <span style={style.navItem}>{t("navbar.hardwareLogs")}</span>
              </div>
            </Link>
          ),
          role: ["SUPER_ADMIN_ROLE"],
        },
        {
          key: "maintenancelogs",
          label: (
            <Link to="/app/main/logs/maintenance-logs">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <FileTextOutlined />
                <span style={style.navItem}>{t("navbar.maintenanceLogs")}</span>
              </div>
            </Link>
          ),
          role: ["SUPER_ADMIN_ROLE", "ADMIN_ROLE"],
        },
      ],
    },
    {
      label: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TeamOutlined />
          {!isMobile && <span style={style.navItem}>{t("navbar.users")}</span>}
        </div>
      ),
      key: "users",
      role: ["ADMIN_ROLE", "SUPER_ADMIN_ROLE"],
      children: [
        {
          key: "super-admins",
          label: (
            <Link to="/app/main/users/super-admins">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <TeamOutlined />
                <span style={style.navItem}>{t("navbar.superAdmins")}</span>
              </div>
            </Link>
          ),
          role: ["SUPER_ADMIN_ROLE"],
        },
        {
          key: "admins",
          label: (
            <Link to="/app/main/users/admins">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <TeamOutlined />
                <span style={style.navItem}>{t("navbar.admins")}</span>
              </div>
            </Link>
          ),
          role: ["ADMIN_ROLE", "SUPER_ADMIN_ROLE"],
        },
        {
          key: "technicians",
          label: (
            <Link to="/app/main/users/technicians">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <TeamOutlined />
                <span style={style.navItem}>{t("navbar.technicians")}</span>
              </div>
            </Link>
          ),
          role: ["ADMIN_ROLE", "SUPER_ADMIN_ROLE"],
        },
        {
          key: "drivers",
          label: (
            <Link to="/app/main/users/drivers">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <TeamOutlined />
                <span style={style.navItem}>{t("navbar.drivers")}</span>
              </div>
            </Link>
          ),
          role: ["ADMIN_ROLE", "SUPER_ADMIN_ROLE"],
        },
        {
          key: "Kiosk-users",
          label: (
            <Link to="/app/main/users/kiosk-users">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <TeamOutlined />
                <span style={style.navItem}>{t("navbar.kioskUsers")}</span>
              </div>
            </Link>
          ),
          role: ["SUPER_ADMIN_ROLE"],
        },
        {
          key: "validator-users",
          label: (
            <Link to="/app/main/users/validator-users">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <TeamOutlined />
                <span style={style.navItem}>{t("navbar.validatorUsers")}</span>
              </div>
            </Link>
          ),
          role: ["SUPER_ADMIN_ROLE"],
        },
      ],
    },
  ];

  const filteredItems = items.filter((item) => {
    // Filter children based on roles
    const filteredChildren = item.children?.filter((child) =>
      profile?.user?.roles?.some((UserRole) => child.role?.includes(UserRole))
    );

    // If the item itself matches the roles or it has children that match, include it
    const hasMatchingRole = profile?.user?.roles?.some((UserRole) =>
      item.role?.includes(UserRole)
    );

    // If there are no children that match and the item itself doesn't match, exclude it
    if (
      !hasMatchingRole &&
      (!filteredChildren || filteredChildren.length === 0)
    ) {
      return false;
    }

    // Include the item and its filtered children
    if (filteredChildren && filteredChildren.length > 0) {
      item.children = filteredChildren;
    }

    return true;
  });
  const { user } = useSelector((state) => state.users.profile);

  // Language flags and names
  const languages = {
    en: { name: "English", flag: enFlag },
    it: { name: "Italian", flag: itFlag },
  };

  // Language menu
  const languageMenu = (
    <Menu
      onClick={(e) => {
        dispatch(changePreferredLanguage(e.key)) // Change preferred language
          .unwrap()
          .then(
            () => message.success("Language changed successfully") // Show success message
          )
          .catch((error) =>
            message.error("Error while changing preferred language")
          ); // Show error message
        console.log("Language selected:", e.key);
      }}
    >
      <Menu.Item key="en">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <img
            src={languages.en.flag}
            alt="English"
            style={{
              width: "20px",
              height: "20px",
              marginRight: "8px",
            }}
          />
          <b>English</b>
        </div>
      </Menu.Item>
      <Menu.Item key="it">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <img
            src={languages.it.flag}
            alt="Italian"
            style={{
              width: "20px",
              height: "20px",
              marginRight: "8px",
            }}
          />
          <b>Italian</b>
        </div>
      </Menu.Item>
    </Menu>
  );

  const [left, setLeft] = useState(0);
  const [right, setRight] = useState(0);

  return (
    <>
      <Header id="navbar" style={horizontalBarStyle}>
        {!isMobile && (
          <img
            style={{
              width: "10em",
              objectFit: "fill",
            }}
            src={TicketBossLogo}
            alt="logo"
          />
        )}

        <Menu
          mode="horizontal"
          items={filteredItems}
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "85%",
            borderColor: "transparent",
          }}
          selectedKeys={activeMenu}
        />

        {profile.user ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Dropdown
              overlay={languageMenu}
              trigger={["click"]}
              autoAdjustOverflow
              placement="topRight"
            >
              <Button
                type="default"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%", // Makes the button rounded
                  padding: "0", // Remove padding to ensure the flag fits perfectly
                  width: "25px", // Adjust the width and height according to your needs
                  height: "25px",
                  marginRight: "2rem",
                  border: "none", // Remove border
                }}
              >
                <img
                  src={languages[user?.preferredLanguage].flag}
                  alt={languages[user?.preferredLanguage].name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    borderRadius: "50%", // Makes the flag image rounded
                  }}
                />
              </Button>
            </Dropdown>
            <Dropdown overlay={userProfileMenu}>
              <div
                style={{
                  // marginRight: "5.1em",
                  width: "auto",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <CustomAvatar
                  name={`${profile?.user?.name} ${profile?.user?.surname}`}
                  type="initials"
                  size={25}
                />
                <Title
                  level={5}
                  style={{
                    margin: 0,
                    whiteSpace: "nowrap",
                  }}
                >
                  {`${profile?.user?.name}`}
                </Title>
              </div>
            </Dropdown>
          </div>
        ) : (
          <Spin />
        )}
      </Header>
    </>
  );
};

export default NavBar;
