import React, { useMemo } from "react";
import {
  G2,
  Chart,
  Tooltip,
  Interval,
  Coord
} from "bizcharts";
import { useTranslation } from "react-i18next";

const TransactionsStatistics = (data) => {
    const { t } = useTranslation()
    const sortedData = useMemo(() => {
        if (data?.data) {
            let  sortedData = [...data?.data].sort((a, b) => new Date(a.month) - new Date(b.month));
            sortedData = sortedData.map((item) => {
                let newItem = {...item};
                if(newItem.type == "STANDARD_CARD"){
                    newItem.type = t("dashboard.transactionStatistics.standardCard")
                    return newItem;
                }else if(newItem.type == "SPECIAL_CARD"){
                    newItem.type = t("dashboard.transactionStatistics.specialCard")
                    return newItem;
                }else if(newItem.type == "TICKET"){
                    newItem.type = t("dashboard.transactionStatistics.ticket")
                    return newItem;
                }
            })
          return sortedData;
        }
        return [];
      }, [data]);

    return (
        <Chart
        id={"transaction"}
        height={400}
        padding="auto"
        scale={{
          value: { min: 0 },
          month: { type: 'cat', alias: 'Month' }, // define month as a category
        }}
        data={sortedData}  // Use sorted data
        autoFit
      >
        <Interval
          adjust={[{ type: 'dodge', marginRatio: 0 }]}  // Dodge to compare across types
          color="type"  // Color bars by 'type'
          position="month*value"  // Position by month and value
        />
        <Tooltip shared />
      </Chart>
    );
}

export default TransactionsStatistics;