import { combineReducers, configureStore } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import authSlice from "./authSlice";
import kiosksSlice from "./kiosksSlice";
import userSlice from "./userSlice";
import fileSlice from "./fileSlice";
import bundleSlice from "./bundleSlice";
import transactionSlice from "./transactionSlice";
import cardSlice from "./cardSlice";
import ticketSlice from "./ticketSlice";
import statisticsSlice from "./statisticsSlice";
import hardwareSlice from "./hardwareSlice";
import validatorLogSlice from "./validatorLogSlice";
import hardwareLogSlice from "./hardwareLogSlice";
import validatorSlice from "./validatorSlice";
import maintenanceLogSlice from "./maintenanceLogSlice";
import cardTypeSlice from "./cardTypeSlice";
import { composeWithDevTools } from "redux-devtools-extension";
import dashboardSlice from "./dashboardSlice";

const persistConfig = {
  key: "auth",
  storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  auth: authSlice,
  users: userSlice,
  files: fileSlice,
  kiosks: kiosksSlice,
  statistics: statisticsSlice,
  bundles: bundleSlice,
  transactions: transactionSlice,
  validatorLogs: validatorLogSlice,
  hardwareLogs: hardwareLogSlice,
  cards: cardSlice,
  tickets: ticketSlice,
  validators: validatorSlice,
  hardwares: hardwareSlice,
  maintenanceLogs: maintenanceLogSlice,
  cardTypes: cardTypeSlice,
  dashboard: dashboardSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  });

const store = configureStore({
  reducer: persistedReducer,
  middleware,
  devTools: process.env.NODE_ENV === "development",
});

export const persistor = persistStore(store);
export default store;
