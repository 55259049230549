import React, { useEffect, useState } from "react";
import Header from "../../global/Header/Header";
import humanize from "humanize-duration"
import { useDispatch, useSelector } from "react-redux";
import {
  changeValidatorStatus,
  clearSelectedValidator,
  deleteValidator,
  getAllValidators,
} from "../../redux/validatorSlice";
import { Card, Typography, message } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import { style } from "../../Styles";
import ActionButtonWithChangeStatus from "../CommonComponents/ActionButtonWithChangeStatus";
import CustomTableComponents from "../CommonComponents/CustomTableComponents";
import ValidatorDrawer from "./ValidatorDrawer";
import { formatDateAndTime } from "../../Utils/Imports/date-utils";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const ValidatorsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState("");
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedValidatorId, setSelectedValidatorId] = useState(null);

  const [csvHeaders, setCsvHeaders] = useState([]);

  const { validators } = useSelector((state) => state.validators);

  const fetchAllValidators = () => {
    dispatch(getAllValidators())
      .unwrap()
      .then(() => { })
      .catch(() =>
        message.error(
          t("validatorScreen.messages.anErrorOccurredWhileFetchingValidators")
        )
      );
  };

  const handleDeleteValidator = (id) => {
    dispatch(deleteValidator(id))
      .unwrap()
      .then(() => {
        message.success(
          t("validatorScreen.messages.validatorDeletedSuccessfully")
        );
      })
      .catch(() =>
        message.error(
          t("validatorScreen.messages.anErrorOccurredWhileDeletingValidator")
        )
      );
  };

  const handleSelectRow = (id) => {
    setSelectedValidatorId(id);
    setIsDrawerVisible(true);
  };

  const handleChangeStatus = (record) => {
    dispatch(
      changeValidatorStatus({
        id: record.id,
        status: !record.active,
      })
    )
      .unwrap()
      .then(() => {
        message.success(
          t("validatorScreen.messages.validatorStatusChangedSuccessfully")
        );
      })
      .catch((e) => {
        message.error(e.message);
      });
  };
  useEffect(() => {
    fetchAllValidators();
  }, [dispatch]);

  const columns = [
    {
      title: t("validatorScreen.table.id"),
      key: "id",
      render: (record) => {
        return record.id ? <span>{record.id}</span> : <MinusOutlined />;
      },
      sorter: (a, b) => a.id - b.id,
      show: true,
    },
    {
      title: t("validatorScreen.table.serialNumber"),
      key: "serialNumber",
      render: (record) => {
        return record.serialNumber ? (
          <Text copyable keyboard>
            {record.serialNumber}
          </Text>
        ) : (
          <MinusOutlined />
        );
      },

      sorter: (a, b) => a?.serialNumber?.localeCompare(b?.serialNumber),
      show: true,
    },


    {
      title: t("validatorScreen.table.lastPing"),
      key: "lastPing",
      render: (record) => {
        return record.lastPing ? (
          <Text title={humanize((new Date(record.lastPing).getTime() - new Date().getTime()), { units: ["h", "m"], round: true }) + " ago"}>
            <span>{formatDateAndTime(record.lastPing)}</span>
          </Text>
        ) : (
          <MinusOutlined />
        );
      },
      show: true,
    },
    {
      title: t("validatorScreen.table.lastScan"),
      key: "lastScan",
      render: (record) => {
        return record.lastScan ? (
          <Text title={humanize((new Date(record.lastScan).getTime() - new Date().getTime()), { units: ["h", "m"], round: true }) + " ago"}>
            <span>{formatDateAndTime(record.lastScan)}</span>
          </Text>
        ) : (
          <MinusOutlined />
        );
      },
      show: true,
    },
    {
      title: t("validatorScreen.table.state.name"),
      key: "state",
      render: (record) => {
        const fifteenMinutesAgo = new Date().getTime() - 15 * 60 * 1000;
        const isOffline = !record.lastPing || new Date(record.lastPing).getTime() < fifteenMinutesAgo;
        return (
          <span style={style.dataContainer}>
            {isOffline ? (
              <div style={style.statusInactif}>{t("validatorScreen.table.state.offline")}</div>
            ) : (
              <div style={style.statusActif}>{t("validatorScreen.table.state.online")}</div>
            )}
          </span>
        );
      },
      sorter: (a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1),
      show: true,
    },
    {
      title: t("validatorScreen.table.status"),
      key: "active",
      render: (record) => {
        return (
          <span style={style.dataContainer}>
            {record.active ? (
              <div style={style.statusActif}>{t("statuses.active")}</div>
            ) : (
              <div style={style.statusInactif}>{t("statuses.inactive")}</div>
            )}
          </span>
        );
      },
      sorter: (a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1),
      show: true,
    },
    {
      title: t("validatorScreen.table.actions"),
      key: "actions",
      render: (text, record) => (
        <ActionButtonWithChangeStatus
          record={record}
          onDelete={() => handleDeleteValidator(record.id)}
          onRowClick={() => handleSelectRow(record.id)}
          onChangeStatus={() => handleChangeStatus(record)}
        />
      ),
      show: true,
    },
  ];

  const filteredData = validators?.data?.filter((item) => {
    return (
      item?.id?.toString()?.toLowerCase()?.includes(filter.toLowerCase()) ||
      item.serialNumber.toLowerCase().includes(filter.toLowerCase())
    );
  });

  const handleGenerateCsv = (event, done) => {
    const promise = async () => {

      setCsvHeaders([
        {
          label: "ID",
          key: "id",
        },
        {
          label: "Serial Number",
          key: "serialNumber",
        },
        {
          label: "Status",
          key: "active",
        },
        {
          label: "Last Ping",
          key: "lastPing",
        },
        {
          label: "Last Scan",
          key: "lastScan",
        },
      ]);
    };

    promise().then(done());
  };

  return (
    <>
      <Header
        addButtonText={t("validatorScreen.form.addValidator")}
        onAddButtonClick={() => setIsDrawerVisible(true)}
        onSearch={(e) => {
          setFilter(e.target.value);
        }}
        searchPlaceholder={t("validatorScreen.header.search")}
        addButtonTextColor={"#ffffff"}
        onReload={fetchAllValidators}
        showAddButton={true}
        BreadcrumbItems={[
          {
            title: t("validatorScreen.header.title"),
          },
        ]}
        showBreadcrumb={true}
        showExportCSV={true}
        csvHeaders={csvHeaders}
        csvData={filteredData}
        generateCSVfn={handleGenerateCsv}
        csvDataMiddleFileName={"Hardware-logs"}
      />
      <Card style={{ margin: 16, height: "65vh" }}>
        <CustomTableComponents
          isMakingApiCall={validators?.loading}
          columns={columns.filter((column) => column.show)}
          dataArray={filteredData}
          handleDoubleClickRow={(record) => handleSelectRow(record.id)}
          handleSelectedRow={() => {}}
        />
        {isDrawerVisible && (
          <ValidatorDrawer
            validatorId={selectedValidatorId}
            setValidatorId={setSelectedValidatorId}
            onClose={() => {
              setSelectedValidatorId(null);
              setIsDrawerVisible(false);
              dispatch(clearSelectedValidator());
            }}
            dispatch={dispatch}
          />
        )}
      </Card>
    </>
  );
};

export default ValidatorsList;
